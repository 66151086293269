<template>
    <div class="card-ticket">
        <div class="row">
            <div class="xs12 pa-3 pb-2">
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{ GioXuatBen }}
                    </div>
                    <i class="mdi mdi-minus ml-1" />
                    <div class="mr-2">
                        <i class="mdi mdi-card-bulleted-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.BienKiemSoat }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2 color-primary">
                        <i class="mdi mdi-bus-stop"></i>
                    </div>
                    <div :class="textRight" class="color-primary">
                        {{ `${item.TenBenXeDi}` }}
                    </div>
                    <i class="mdi mdi-minus ml-1" />
                    <div class="mr-2 color-success">
                        <i class="mdi mdi-map-marker-down"></i>
                    </div>
                    <div :class="textRight" class="color-success">
                        {{ `${item.TenBenXeDen}` }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-code-equal"></i>
                    </div>
                    <div :class="textRight">
                        {{ `${item.MaTuyen}` }}
                    </div>
                </div>

                <div :class="rowText" v-if="item.DanhSachLaiXeThucHien.length > 0">
                    <div class="mr-2">
                        <i class="mdi mdi-steering"></i>
                    </div>
                    <div
                        style="
                            line-height: 19px;
                            display: block;
                            float: left;
                            margin: 2px 0;
                        "
                        v-for="(itLaiXe, index) in item.DanhSachLaiXeThucHien"
                        :key="itLaiXe"
                        :class="`${textRight} ${index == 0 ? ' color-warning' : ''}`"
                        :title="
                            'Lái xe ' +
                            (index + 1) +
                            ': ' +
                            (!!itLaiXe ? itLaiXe.HoTen : '')
                        "
                    >
                        {{ !!itLaiXe ? itLaiXe.HoTen : ""
                        }}{{
                            item.DanhSachLaiXeThucHien.length - 1 > index
                                ? "\xa0-\xa0"
                                : ""
                        }}
                    </div>
                </div>

                <div :class="rowText" v-if="item.HoTenPhuXe">
                    <div class="mr-2">
                        <i class="mdi mdi-account-tie-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.HoTenPhuXe }}
                    </div>
                </div>

                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-account-group-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.TenDoiXe }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="xs12">
                <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
                    <div
                        class="xs6 text-xs-center"
                        style="border-right: 1px solid #dadce0"
                    >
                        <DxButton
                            text="Sửa kế hoạch"
                            type="success"
                            styling-mode="text"
                            width="100%"
                            :disabled="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.CapNhatKeHoach'),
                                )
                            "
                            @click="SuaKeHoach(item)"
                        />
                    </div>
                    <div class="xs6 text-xs-center">
                        <DxButton
                            text="Ký lệnh"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            :disabled="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.KyLenh'),
                                )
                            "
                            @click="KyLenh(item)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            rowText: "row mb-1 align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
        };
    },
    computed: {
        GioXuatBen() {
            return (this.item.GioXuatBen || "").substr(0, 5);
        },
        ThongTinSuaChuyenDiKeHoach: {
            get() {
                return this.$store.state.DVVTLenh.ThongTinSuaChuyenDiKeHoach;
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "ThongTinSuaChuyenDiKeHoach",
                    data: data,
                });
            },
        },
    },
    methods: {
        KyLenh() {
            this.$store.commit("DVVTLenh/Reset_ThongTinKyLenh");
            this.$router.push({
                path: "/DNVT-Quan-Ly-Lenh/Ky-Lenh",
                query: {
                    IdDnvtTuyen: this.item.IdDnvtTuyen,
                    IdBenDi: this.item.IdBenXeDi,
                    IdBenDen: this.item.IdBenXeDen,
                    TenTuyen: `${this.item.TenBenXeDi} - ${this.item.TenBenXeDen}${
                        this.item.MaTuyen ? ` (${this.item.MaTuyen})` : ``
                    }`,
                    TenBenDi: this.item.TenBenXeDi,
                    TenBenDen: this.item.TenBenXeDen,
                    MaTuyen: this.item.MaTuyen,
                    ID_KeHoach: this.item.ID_KeHoach,
                    IdDoiXe: this.item.IdDoiXe,
                },
            });
        },
        async SuaKeHoach() {
            await this.$store.commit("DVVTLenh/Reset_ThongTinSuaChuyenDiKeHoach");
            this.ThongTinSuaChuyenDiKeHoach = {
                ...this.ThongTinSuaChuyenDiKeHoach,
                ...this.item,
                GioXuatBen: this.GioXuatBen,
            };
            this.$router.push({
                path: "/DNVT-Quan-Ly-Lenh/Sua-Ke-Hoach",
            });
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}
</style>
